@keyframes blink {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.parent-css{
  background-color: darkseagreen;
}

.footer-pos{
  position: relative;
  margin-top: 8%;
  height: 12%;
  width: 100%;
  background-image: url("./images/university.JPG");
  transform: rotate(1.5deg);
}

.header-pos{
  background-color: rgb(169, 233, 233);
  background-image: url("./images/university.JPG");
  transform: rotate(358deg);
}

.my-card {
  margin: 0px;
}

.my-card-image{
  border-radius: 100px;
  width: 300px;
  height: 380px;
  box-shadow: 4px 4px 20px 20px rgb(110, 99, 99);
}

.my-card-info{
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 100px;
}

.my-card-title{
  font-size: 24px;
  margin: 0px;
}

.my-card-subtitle{
  font-size: 16px;
  margin: 0px;
}

.success-msg {
  color: #57a773;
  padding: 10px 15px;
}

.success-msg {
  color: #EE6352;
  padding: 10px 15px;
}

.social-container {
  background: #eee;
  padding: 25px 50px;
  margin-top: 10px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: #49a1eb;
}

a.stackoverflow{
  color: orange;
}

.sliding-pane-click-img{
  border-radius: 50%;
  width: 70px;
  height: 70px;
  box-shadow: 5px 5px 15px 15px rgb(110, 99, 99);
  animation: blink 0.5s;
  animation-iteration-count: infinite;
}

.education-slide{
  background-image: url("./images/university.JPG");
  height: 100%;
  width: 100%;
  background-color: gray;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.academic_slide{
  background-image: url("./images/acad_proj_bg.JPG");
  height: 100%;
  width: 100%;
  background-color: gray;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.project_slide{
  background-image: url("./images/prof_proj_bg.JPG");
  height: 100%;
  width: 100%;
  background-color: gray;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.slider-container {
  width: 75%;
  /* height: 100%; */
  position: fixed;
  top: 500px;
  bottom: 800px;
  left: 15%;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 30%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.iframe{
  width: 50%;
}

